import { useSortPanel } from "./useSortPanel";
import { SortSearchResult } from "../sort-search-result";
import { ExportSearchResult } from "../export-search-result";
import { useClickOutside } from "@/hooks";
import { useRef } from "react";

type SortPanelProps = {
  queryId: string | undefined;
};

export const SortPanel = ({ queryId }: SortPanelProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const {
    containerRef: sortRef,
    isOpen: isSortOpen,
    setIsOpen: setIsSortOpen,
  } = useClickOutside(buttonRef);

  const {
    containerRef,
    exportButtonRef,
    isExportOpen,
    sortValue,
    selectedExportValue,
    setIsExportOpen,
    getLabelByValue,
    handleSortClick,
    handleExportClick,
    toggleDropdown,
  } = useSortPanel({ queryId, setIsSortOpen });

  return (
    <div className="flex items-center gap-3 md:justify-between">
      <SortSearchResult
        sortRef={sortRef}
        buttonRef={buttonRef}
        sortValue={sortValue}
        isSortOpen={isSortOpen}
        setIsSortOpen={setIsSortOpen}
        getLabelByValue={getLabelByValue}
        handleSortClick={handleSortClick}
        toggleDropdown={toggleDropdown}
      />
      <ExportSearchResult
        buttonRef={exportButtonRef}
        containerRef={containerRef}
        setIsExportOpen={setIsExportOpen}
        handleExportClick={handleExportClick}
        selectedExportValue={selectedExportValue}
        isExportOpen={isExportOpen}
        toggleDropdown={toggleDropdown}
      />
    </div>
  );
};
