import React, { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Intercom from "@intercom/messenger-js-sdk";
import { verifyMe } from "@/helpers/verify-me.ts";

// Constants
const COOKIE_NAMES = {
  SOURCE: "sn",
  PARTNER: "pn",
};
const COOKIE_DURATION_DAYS = 180;
// Ad platform URL patterns
const AD_PATTERNS = {
  google: /gclid=/i,
  microsoft: /msclkid=/i,
  facebook: /fbclid=/i,
  linkedin: /li_fat_id=/i,
};

// Utility functions for cookie management
const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

const getCookie = (name) => {
  const cookieName = `${name}=`;
  const cookies = document.cookie.split(";");

  for (let cookie of cookies) {
    cookie = cookie.trim();
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
};

// Detect ad platform source from URL
const detectAdPlatformSource = (url) => {
  if (AD_PATTERNS.google.test(url)) return "google_ads";
  if (AD_PATTERNS.microsoft.test(url)) return "microsoft_ads";
  if (AD_PATTERNS.facebook.test(url)) return "facebook_ads";
  if (AD_PATTERNS.linkedin.test(url)) return "linkedin_ads";
  return null;
};

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    try {
      const decodedToken = verifyMe();
      const intercomSettings = {
        app_id: "z2jxudhe",
      };

      if (decodedToken) {
        intercomSettings.user_id = decodedToken.account_id; // Use account_id as user_id
        intercomSettings.name = decodedToken.first_name; // Use first name
        intercomSettings.email = decodedToken.email; // Use email from token
      }

      Intercom(intercomSettings);
    } catch (error) {
      console.error("Error initializing Intercom:", error);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    // Handle tracking
    const handleTracking = () => {
      // Source attribution hierarchy (highest to lowest priority)
      const explicitSource = searchParams.get("sn");
      const adPlatformSource = detectAdPlatformSource(location.search);
      const utmSource = searchParams.get("utm_source");

      // Set source based on priority
      const finalSource = explicitSource || adPlatformSource || utmSource;
      if (finalSource && !getCookie(COOKIE_NAMES.SOURCE)) {
        setCookie(COOKIE_NAMES.SOURCE, finalSource, COOKIE_DURATION_DAYS);
      }

      // Handle partner tracking
      const partner = searchParams.get("pn");
      if (partner && !getCookie(COOKIE_NAMES.PARTNER)) {
        setCookie(COOKIE_NAMES.PARTNER, partner, COOKIE_DURATION_DAYS);
      }
    };

    handleTracking();
  }, [searchParams, location.search]);

  return (
    <div className="flex min-h-screen w-full flex-col overflow-y-auto overflow-x-hidden">
      <Header />
      <main className="flex-grow">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
