import React, { useState, useEffect } from "react";
import {
  Drawer,
  Typography,
  IconButton,
  Textarea,
} from "@material-tailwind/react";
import { Clipboard } from "flowbite-react";
import { verifyMe } from "/src/helpers/verify-me";
function TrackingCodeDrawer({ open, onClose }) {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      setError("");
      try {
        const decodedToken = verifyMe();
        setUserData(decodedToken);
      } catch (error) {
        console.error("Error verifying user:", error);
        setError(
          "Fehler bei der Benutzerverifizierung. Bitte melden Sie sich erneut an.",
        );
      } finally {
        setIsLoading(false);
      }
    }
  }, [open]);

  const accountId = userData?.account_id || "";
  const trackingCode = `<script src="https://t.webmetic.de/iav.js?id=${accountId}" async></script>`;

  const privacyPolicyText = `Diese Webseite nutzt Webmetic für Zwecke des Marketings, der Marktforschung und der Optimierung. Gemäß Art. 6 Abs. 1 lit. f DSGVO werden unternehmensbezogene Daten erfasst und pseudonymisiert. Hierfür wird ein spezieller Code implementiert, der relevante Daten sicher erfasst. Die Daten werden ab der Administration verschlüsselt, um ihre Integrität zu gewährleisten. Die erfassten Daten werden ausschließlich pseudonymisiert verarbeitet und nicht dazu verwendet, einzelne Besucher dieser Webseite persönlich zu identifizieren, was weder zulässig noch Bestandteil unserer Prozesse ist. Die Daten werden gelöscht, sobald der Zweck der Verarbeitung erfüllt ist und keine gesetzlichen Aufbewahrungspflichten mehr bestehen. Sie können der Datenerhebung und -speicherung jederzeit mit Wirkung für die Zukunft widersprechen, indem Sie diesen Link https://t.webmetic.de/opt-out/ öffnen. Dort können Sie ein Opt-out aktivieren.`;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      placement="right"
      className="h-full overflow-y-auto border-l drop-shadow-lg dark:border-l-gray-600 dark:bg-gray-800 sm:w-full md:w-2/3 lg:w-1/2 2xl:w-1/3"
      size={10000}
      overlay={true}
      overlayProps={{
        className: "fixed inset-0 bg-black bg-opacity-60",
      }}
    >
      <div className="-mt-[1px] flex items-center justify-between border-b px-4 py-3 dark:border-b-gray-600">
        <Typography
          variant="h4"
          color="blue-gray"
          className="dark:text-green-400"
        >
          Tracking Code
        </Typography>
        <IconButton
          variant="text"
          color="blue-gray"
          onClick={onClose}
          className="dark:text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>
      <div className="p-4">
        <h2 className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
          Webmetic Tracking Code
        </h2>
        <p className="mb-6 text-gray-500 dark:text-gray-400">
          Bitte integrieren Sie den folgenden JavaScript-Code auf Ihrer Website:
        </p>

        {isLoading ? (
          <p className="text-gray-500 dark:text-gray-400">Lade Daten...</p>
        ) : error ? (
          <div className="mb-4 rounded-lg bg-red-100 p-4 text-red-700 dark:bg-red-200 dark:text-red-800">
            {error}
          </div>
        ) : (
          <>
            <div className="relative mb-4">
              <input
                value={trackingCode}
                readOnly
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
              />
              <div className="absolute right-20 top-1/2">
                <Clipboard.WithIconText
                  className=""
                  valueToCopy={trackingCode}
                />
              </div>
            </div>

            <p className="mb-4 text-gray-500 dark:text-gray-400">
              Fügen Sie diesen Code in den Quelltext Ihrer Website ein,
              idealerweise direkt über dem schließenden &lt;/body&gt;-Tag. Sie
              können den Code entweder manuell einfügen oder unser Google Tag
              Manager Template verwenden.
            </p>

            <h3 className="mb-4 border-t pt-4 text-lg font-semibold text-gray-900 dark:border-t-gray-600 dark:text-white">
              Google Tag Manager Template
            </h3>
            <p className="mb-4 text-gray-500 dark:text-gray-400">
              Ihre Account-ID lautet: {accountId}
            </p>
            <p className="mb-4 text-gray-500 dark:text-gray-400">
              Um die Integration zu vereinfachen, können Sie unser offizielles
              Google Tag Manager Template verwenden. Klicken Sie auf den
              untenstehenden Button, um das Template in der Google Tag Manager
              Galerie zu öffnen:
            </p>
            <button
              color="blue"
              className="btn mb-4"
              onClick={() =>
                window.open(
                  "https://tagmanager.google.com/gallery/#/owners/yanniksuess/templates/webmetic-tag-manager",
                  "_blank",
                )
              }
            >
              GTM Template öffnen
            </button>
            <h3 className="mb-4 border-t pt-4 text-lg font-semibold text-gray-900 dark:border-t-gray-600 dark:text-white">
              Datenschutzerklärung
            </h3>
            <p className="mb-4 text-gray-500 dark:text-gray-400">
              Den folgenden Text können Sie für Ihre Datenschutzerklärung
              verwenden:
            </p>
            <div className="relative mb-4">
              <textarea
                value={privacyPolicyText}
                readOnly
                rows={12}
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
              />
              <div className="absolute -bottom-16 right-24 top-1/2">
                <Clipboard.WithIconText
                  className=""
                  valueToCopy={privacyPolicyText}
                />
              </div>
            </div>

            <p className="mt-6 text-gray-500 dark:text-gray-400">
              Sollten Sie Unterstützung benötigen, stehen wir Ihnen gerne zur
              Verfügung. Bei Unklarheiten kontaktieren Sie uns bitte. Wir helfen
              Ihnen gerne bei der Integration.
            </p>
          </>
        )}
      </div>
    </Drawer>
  );
}

export default TrackingCodeDrawer;
