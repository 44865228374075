import { SearchInput } from "@/components";
import { COMPANIES_LIST_OPTIONS } from "@/constants";
import { useSearchParams } from "react-router-dom";

type CompaniesListSearchProps = {
  searchValue: string;
  onChangeSearch: (val: string) => void;
  dropDownValue?: string;
  onChangeDropdownValue: (val: string) => void;
  queryId: string;
};

export const CompaniesListSearch = (props: CompaniesListSearchProps) => {
  const [searchParams] = useSearchParams();

  const isSegmentSearch = searchParams.has("segment_id");

  const segmentOption = [
    {
      value: "Segment search",
      label: "Segment search",
    },
  ];

  return (
    <div className="relative w-full">
      <SearchInput
        isSegmentSearch={isSegmentSearch}
        categoryList={isSegmentSearch ? segmentOption : COMPANIES_LIST_OPTIONS}
        dropDownValue={
          isSegmentSearch ? segmentOption[0].value : props.dropDownValue
        }
        {...props}
      />
    </div>
  );
};
