import { ReactNode } from "react";

type AppLayoutProps = {
  children: ReactNode;
};

export const AppLayout = ({ children }: AppLayoutProps) => (
  <div className="flex h-screen w-full overflow-hidden dark:bg-gray-900">
    {children}
  </div>
);
