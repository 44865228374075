import { jwtDecode, JwtPayload } from "jwt-decode";

export interface DecodedTokenData extends JwtPayload {
  token: string;
  account_id: string;
  name: string;
  website: string[];
  email: string;
  first_name: string;
  last_name: string;
}

export function verifyMe() {
  const accessToken = localStorage.getItem("token");
  if (!accessToken) {
    localStorage.removeItem("token");
    return null; // Return null if the token is missing
  }
  const decodedToken = jwtDecode<DecodedTokenData>(accessToken);
  if (
    typeof decodedToken.exp === "number" &&
    decodedToken.exp < Date.now() / 1000
  ) {
    localStorage.removeItem("token");
    return null; // Return null if the token is expired
  }
  return decodedToken;
}