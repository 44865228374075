import { SORT_LIST_SEARCH_OPTIONS } from "@/constants/sortListSearchOptions";
import clsx from "clsx";
import { ChevronDown, CheckIcon, ChevronUp } from "lucide-react";
import { Dispatch, RefObject, SetStateAction } from "react";

type SortSearchResultProps = {
  classNames?: string;
  sortRef: RefObject<HTMLDivElement>;
  buttonRef: RefObject<HTMLButtonElement>;
  sortValue: string;
  isSortOpen: boolean;
  setIsSortOpen: Dispatch<SetStateAction<boolean>>;
  getLabelByValue: (value?: string) => JSX.Element | "Sortierung";
  handleSortClick: (value: string) => void;
  toggleDropdown: (value: Dispatch<SetStateAction<boolean>>) => void;
};

export const SortSearchResult = ({
  classNames,
  sortValue,
  isSortOpen,
  setIsSortOpen,
  getLabelByValue,
  handleSortClick,
  toggleDropdown,
  sortRef,
  buttonRef,
}: SortSearchResultProps) => {
  return (
    <div
      className={clsx(
        "relative flex items-center gap-1 dark:text-white",
        classNames,
      )}
    >
      <button
        id="dropdown-sort-button"
        data-dropdown-toggle="dropdown-sort"
        className={clsx(
          "w-30 flex items-center justify-between gap-2 rounded-md border px-3 py-1 text-sm dark:border-gray-600 dark:bg-gray-700",
        )}
        type="button"
        onClick={() => toggleDropdown(setIsSortOpen)}
        ref={buttonRef}
      >
        {getLabelByValue(sortValue)}
        {isSortOpen ? <ChevronUp /> : <ChevronDown />}
      </button>
      {isSortOpen ? (
        <div
          id="dropdown-sort"
          className="w-38 absolute top-[37px] z-10 divide-y divide-gray-100 rounded-lg bg-white shadow dark:bg-gray-700"
          ref={sortRef}
        >
          <ul
            className="gap-1 rounded-md border p-1.5 text-sm text-gray-700 dark:border-gray-600 dark:text-gray-200"
            aria-labelledby="dropdown-sort-button"
          >
            {SORT_LIST_SEARCH_OPTIONS.map(({ value, label, icon }) => {
              const isSelected = sortValue === value;
              return (
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full items-center gap-2 rounded-sm py-[7px] transition-all duration-300 ease-in-out hover:bg-gray-100 dark:hover:bg-gray-500 dark:hover:text-white"
                    onClick={() => handleSortClick(value)}
                  >
                    {isSelected && <CheckIcon height={15} />}
                    <span className={clsx(!isSelected && "ml-8")}>{label}</span>
                    {icon}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
