import { EXPORT_SEARCH_DATA_OPTIONS } from "@/constants/exportSearchDataOptions";
import clsx from "clsx";
import { CheckIcon, ChevronDown, ChevronUp } from "lucide-react";
import { Dispatch, RefObject, SetStateAction } from "react";
import { SortingType } from "./sort-panel/useSortPanel";

type ExportSearchResultProps = {
  classNames?: string;
  containerRef: RefObject<HTMLDivElement>;
  buttonRef: RefObject<HTMLButtonElement>;
  selectedExportValue: SortingType | null;
  isExportOpen: boolean;
  setIsExportOpen: Dispatch<SetStateAction<boolean>>;
  handleExportClick: (item: SortingType) => void;
  toggleDropdown: (value: Dispatch<SetStateAction<boolean>>) => void;
};

export const ExportSearchResult = ({
  containerRef,
  buttonRef,
  classNames,
  selectedExportValue,
  isExportOpen,
  setIsExportOpen,
  handleExportClick,
  toggleDropdown,
}: ExportSearchResultProps) => {
  return (
    <div
      className={clsx(
        "relative flex items-center gap-1 dark:text-white",
        classNames,
      )}
    >
      <button
        id="dropdown-export-button"
        data-dropdown-toggle="dropdown-export"
        className={clsx(
          "flex w-28 items-center justify-between gap-2 rounded-md border px-3 py-1 text-sm dark:border-gray-600 dark:bg-gray-700",
        )}
        type="button"
        onClick={() => toggleDropdown(setIsExportOpen)}
        ref={buttonRef}
      >
        {selectedExportValue?.label || "Export"}
        {isExportOpen ? <ChevronUp /> : <ChevronDown />}
      </button>
      {isExportOpen ? (
        <div
          id="dropdown-export"
          className={`absolute top-[37px] z-10 w-full divide-y divide-gray-100 rounded-lg bg-white shadow dark:bg-gray-700`}
          ref={containerRef}
        >
          <ul
            className="gap-1 rounded-md border p-1.5 text-sm text-gray-700 dark:border-gray-600 dark:text-gray-200"
            aria-labelledby="dropdown-sort-button"
          >
            {EXPORT_SEARCH_DATA_OPTIONS.map((option: SortingType) => {
              const { label, value } = option;
              const isSelected = selectedExportValue?.value === value;
              return (
                <li className="group relative">
                  <button
                    type="button"
                    className="inline-flex w-full items-center gap-2 rounded-sm py-[7px] transition-all duration-300 ease-in-out hover:bg-gray-100 dark:hover:bg-gray-500 dark:hover:text-white"
                    onClick={() => handleExportClick(option)}
                  >
                    {isSelected && <CheckIcon height={15} />}
                    <div className="flex items-center gap-[5px]">
                      <img
                        src={option.image}
                        alt="download"
                        height={15}
                        width={15}
                      />
                      <span>{label}</span>
                    </div>
                  </button>

                  <div
                    role="tooltip"
                    className="duration-600 invisible absolute left-[150px] top-[-10px] z-10 mt-2 w-full -translate-x-1/2 transform rounded-lg bg-gray-900 px-2 py-1 text-[9px] font-medium leading-3 text-white opacity-0 transition-opacity delay-500 group-hover:visible group-hover:opacity-100 dark:bg-gray-700"
                  >
                    {option.tooltip}
                    <div className="tooltip-arrow" data-popper-arrow></div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
