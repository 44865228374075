export const GERMAN_LABELS: { [key: string]: string } = {
  today: "Heute",
  yesterday: "Gestern",
  this_week: "Diese Woche",
  last_week: "Letzte Woche",
  this_month: "Dieser Monat",
  one_month_ago: "Vor einem Monat",
  two_months_ago: "Vor zwei Monaten",
  three_months_ago: "Vor drei Monaten",
  this_year: "Dieses Jahr",
  all_time: "Alle",
  january: "Januar",
  february: "Februar",
  march: "März",
  april: "April",
  may: "Mai",
  june: "Juni",
  july: "Juli",
  august: "August",
  september: "September",
  october: "Oktober",
  november: "November",
  december: "Dezember",
  2023: "2023",
  2024: "2024",
};
