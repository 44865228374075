import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { CircleUser } from "lucide-react";
import { Button } from "@/components/ui/button.tsx";
import { DarkThemeToggle } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import SupportDrawer from "@/routes/account/SupportDrawer";
import ApiDetailsDrawer from "@/routes/account/ApiDetailsDrawer";
import CompanyProfileDrawer from "@/routes/account/CompanyProfileDrawer";
import UsersDrawer from "@/routes/account/UsersDrawer";
import ProfileDrawer from "@/routes/account/ProfileDrawer";
import AVContractDrawer from "@/routes/account/AVContractDrawer";
import TrackingCodeDrawer from "@/routes/account/TrackingCodeDrawer";
import WebsitesDrawer from "@/routes/account/WebsitesDrawer";
import React, { useState } from "react";

function HeaderDropdownMenu() {
  const [supportOpen, setSupportOpen] = useState(false);
  const [apiDetailsOpen, setApiDetailsOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [usersOpen, setUsersOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [avContractOpen, setAVContractOpen] = useState(false);
  const [trackingCodeOpen, setTrackingCodeOpen] = useState(false);
  const [websitesOpen, setWebsitesOpen] = useState(false);

  const navigate = useNavigate();

  const toggleDrawer =
    (setter: React.Dispatch<React.SetStateAction<boolean>>) => () => {
      setter((prev) => !prev);
    };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <DropdownMenu>
      <div className="mr-2 hidden sm:block">
        <DarkThemeToggle />
      </div>
      <DropdownMenuTrigger asChild>
        <Button
          variant="secondary"
          size="icon"
          className="rounded-full dark:bg-gray-500 dark:hover:bg-gray-400"
        >
          <CircleUser className="h-5 w-5" />
          <span className="sr-only">Account Menü</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400"
      >
        <DropdownMenuLabel>Mein Konto</DropdownMenuLabel>
        <DropdownMenuSeparator className="dark:bg-gray-600" />
        <DropdownMenuItem onClick={toggleDrawer(setProfileOpen)}>
          Profil
        </DropdownMenuItem>
        <DropdownMenuItem onClick={toggleDrawer(setSettingsOpen)}>
          Firmenprofil
        </DropdownMenuItem>
        <DropdownMenuItem onClick={toggleDrawer(setUsersOpen)}>
          Benutzer
        </DropdownMenuItem>
        <DropdownMenuItem onClick={toggleDrawer(setAVContractOpen)}>
          AV-Vertrag
          <span className="me-2 ml-2 rounded bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 dark:bg-green-900 dark:text-green-300">
            Neu
          </span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={toggleDrawer(setApiDetailsOpen)}>
          API
        </DropdownMenuItem>
        <DropdownMenuItem onClick={toggleDrawer(setTrackingCodeOpen)}>
          Trackingcode
        </DropdownMenuItem>
        <DropdownMenuItem onClick={toggleDrawer(setWebsitesOpen)}>
          Websites
        </DropdownMenuItem>
        <DropdownMenuItem onClick={toggleDrawer(setSupportOpen)}>
          Support
        </DropdownMenuItem>
        <DropdownMenuSeparator className="dark:bg-gray-600" />
        <DropdownMenuItem onClick={handleLogout}>Logout</DropdownMenuItem>
      </DropdownMenuContent>

      <SupportDrawer
        open={supportOpen}
        onClose={toggleDrawer(setSupportOpen)}
      />
      <ApiDetailsDrawer
        open={apiDetailsOpen}
        onClose={toggleDrawer(setApiDetailsOpen)}
      />
      <CompanyProfileDrawer
        open={settingsOpen}
        onClose={toggleDrawer(setSettingsOpen)}
      />
      <UsersDrawer open={usersOpen} onClose={toggleDrawer(setUsersOpen)} />
      <ProfileDrawer
        open={profileOpen}
        onClose={toggleDrawer(setProfileOpen)}
      />
      <AVContractDrawer
        open={avContractOpen}
        onClose={toggleDrawer(setAVContractOpen)}
      />
      <TrackingCodeDrawer
        open={trackingCodeOpen}
        onClose={toggleDrawer(setTrackingCodeOpen)}
      />
      <WebsitesDrawer
        open={websitesOpen}
        onClose={toggleDrawer(setWebsitesOpen)}
      />
    </DropdownMenu>
  );
}

export default HeaderDropdownMenu;
