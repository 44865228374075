import {
  SearchCompanyParams,
  SearchCompanyResponseData,
  apiHandlers,
} from "@/api";
import { PAGINATION_LIMIT, SEARCH_COMPANY } from "@/constants";
import {
  InfiniteData,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from "@tanstack/react-query";

export const useSearchCompanyQuery = (
  params: SearchCompanyParams,
): UseInfiniteQueryResult<InfiniteData<SearchCompanyResponseData>, Error> => {
  return useInfiniteQuery({
    queryKey: [
      SEARCH_COMPANY,
      params.domain,
      params.q,
      params.mode,
      params.segment_id,
      params.from_date,
      params.to_date,
      params.page,
      params.page_size,
      params.sort,
    ],
    queryFn: ({ pageParam }) => {
      return apiHandlers.company
        .multiSearch({
          ...params,
          page: pageParam,
          page_size: PAGINATION_LIMIT,
        })
        .then((res) => {
          return res.data;
        });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const { is_last, current } = lastPage.pagination;
      return is_last ? undefined : current + 1;
    },
    getPreviousPageParam: (firstPage) => {
      const { current, is_first } = firstPage.pagination;
      return is_first ? undefined : current - 1;
    },
  });
};
