import { CompaniesFilterDate } from "@/types";
import {
  subDays,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  getYear,
} from "date-fns";
import { DateRange } from "react-day-picker";
import { toValidRangeDate } from ".";

export const getDateRange = (item: CompaniesFilterDate): DateRange => {
  const today = new Date();
  const currentDayOfWeek = today.getDay();
  const mondayOffset = currentDayOfWeek === 0 ? -6 : 1 - currentDayOfWeek;
  const currentWeekMonday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + mondayOffset,
  );

  switch (item.label) {
    case "today":
      return { from: today, to: today };
    case "yesterday":
      const yesterday = subDays(today, 1);
      const tomorrow = new Date(
        yesterday.getFullYear(),
        yesterday.getMonth(),
        yesterday.getDate() + 1,
      );
      return { from: yesterday, to: tomorrow };
    case "this_week": {
      // Get current day or end of the current week (whichever comes first)
      const endOfCurrentWeek = endOfWeek(today, { weekStartsOn: 1 });
      const currentDay = today < endOfCurrentWeek ? today : endOfCurrentWeek;
      return { from: currentWeekMonday, to: currentDay };
    }
    case "last_week": {
      const lastWeekMonday = subDays(currentWeekMonday, 7); // Start of last week (Monday)
      const nextMondayAfterLastWeek = new Date(
        lastWeekMonday.getFullYear(),
        lastWeekMonday.getMonth(),
        lastWeekMonday.getDate() + 7,
      );
      return { from: lastWeekMonday, to: nextMondayAfterLastWeek };
    }
    case "this_month":
      return { from: startOfMonth(today), to: endOfMonth(today) };
    case "this_year":
      return { from: startOfYear(today), to: endOfYear(today) };
    case "all_time": {
      const fromDate = new Date(-62135596800000);
      return { from: fromDate, to: today };
    }
    default:
      // For specific months or years
      if (
        item?.label?.match(
          /^(january|february|march|april|may|june|july|august|september|october|november|december)$/,
        )
      ) {
        const year = today.getFullYear();
        const month = [
          "january",
          "february",
          "march",
          "april",
          "may",
          "june",
          "july",
          "august",
          "september",
          "october",
          "november",
          "december",
        ].indexOf(item.label);
        const start = new Date(year, month, 1);
        return { from: start, to: endOfMonth(start) };
      }
      if (item?.label?.match(/^\d{4}$/)) {
        const year = parseInt(item.label, 10);
        const currentYear = getYear(new Date());
        if (year === currentYear) {
          return { from: new Date(year, 0, 1), to: new Date() };
        }
        return { from: new Date(year, 0, 1), to: new Date(year + 1, 0, 1) };
      }
      // Default case: use the provided dates
      return {
        from: toValidRangeDate(item.date_start) || undefined,
        to: toValidRangeDate(item.date_end) || undefined,
      };
  }
};
