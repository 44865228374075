import clsx from "clsx";
import { Company } from "@/types";
import { getDaysLeftInGerman, getLeadScoreClass } from "../utils";
import { GermanFlag } from "@/assets/icons";

interface CompanyListItemProps {
  company: Company;
}

function CompanyListItem({ company }: CompanyListItemProps) {
  return (
    <>
      <div className="absolute inset-y-0 -left-1.5 w-1.5 overflow-hidden rounded-l-full">
        <span
          className={clsx(
            "absolute inset-0",
            getLeadScoreClass(company.lead_score),
          )}
        />
        <span
          className="absolute inset-x-0 top-0 bg-gray-200 dark:bg-gray-300"
          style={{ height: `${95 - company.lead_score}%` }}
        />
      </div>

      <div
        className={`relative flex min-h-[92px] cursor-pointer rounded-br-lg rounded-tr-lg bg-white p-3 hover:bg-[#f5faff] group-[.is-active]:bg-[#f5faff] dark:bg-gray-700 dark:hover:bg-gray-600 dark:group-[.is-active]:bg-gray-500`}
      >
        <div className="flex w-full gap-3">
          {/* Column A: Image */}
          <div
            className="h-[70px] w-[70px] flex-shrink-0 rounded bg-white bg-[length:85%] bg-center bg-no-repeat dark:bg-gray-600"
            style={{ backgroundImage: `url(${company.company_logo_url})` }}
          />

          {/* Column B: Company details */}
          <div className="flex min-w-0 flex-1 flex-col justify-start">
            {/* Days left */}
            {company.last_session_timestamp && (
              <div className="text-right text-xs text-[#4d5666] dark:text-gray-300">
                {getDaysLeftInGerman(company.last_session_timestamp)}
              </div>
            )}
            {/* Company name */}
            <div className="mt-1 truncate text-sm font-bold dark:text-white">
              {company.company_name}
            </div>
            {/* City and industry */}
            <div className="mt-1 flex items-center text-xs font-normal">
              <div className="mr-2 overflow-hidden text-ellipsis whitespace-nowrap pr-2 text-xs">
                <div className="text-2xs u-text-ellipsis flex items-center truncate rounded-full border bg-[#f1f5f980] px-1 text-gray-500 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400">
                  <GermanFlag />
                  <span className="u-text-ellipsis text-2xs">
                    {company.city}
                  </span>
                </div>
              </div>
              {company.industry && (
                <span className="max-w-[50%] overflow-hidden text-ellipsis whitespace-nowrap text-xs text-[#161616] dark:text-gray-300">
                  {company.industry}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyListItem;
