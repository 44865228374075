import csv from "../assets/icons/csv.svg";
import linkedin from "../assets/icons/linkedin.svg";
import xlsx from "../assets/icons/excel.svg";
import google from "../assets/icons/google.svg";

export const EXPORT_SEARCH_DATA_OPTIONS = [
  {
    label: "Excel",
    value: "excel",
    image: xlsx,
    tooltip: "Als Excel exportieren",
  },
  {
    label: "CSV",
    value: "csv",
    image: csv,
    tooltip: "Als CSV exportieren",
  },
  {
    label: "LinkedIn",
    value: "linkedin",
    image: linkedin,
    tooltip: "Für LinkedIn Retargeting als CSV exportieren",
  },
  {
    label: "Google",
    value: "google",
    image: google,
    tooltip: "Export im Google Customer Match-Format",
  },
];
