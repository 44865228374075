import { RADIO_OPTIONS } from "@/constants";
import { FormControl, FormField, FormItem } from "@/components/ui/form";
import { useCompanyFilterContext } from "@/hooks/useCompanyFilterContext";
import { Button } from "@/components/ui/button";
import { useEffect } from "react";
import { useNewFilterForm } from "./useNewFilterForm";
import { findFieldByName } from "../../utils";
import { FieldList } from "../field-list";

export const NewFilterForm = () => {
  const { ids, setShowFilterFieldsList, showFilterFieldsList } =
    useCompanyFilterContext();

  const filterKey = `query.${ids.groupId}.${ids.filterId}`;

  const { control, currentFilter, handleBack, setValue } = useNewFilterForm(
    filterKey,
    setShowFilterFieldsList,
  );

  useEffect(() => {
    if (currentFilter && !currentFilter.condition) {
      setValue(`${filterKey}.condition`, RADIO_OPTIONS[0].value);
    }
  }, [currentFilter, filterKey, setValue]);

  return (
    <div className="flex flex-col gap-4">
      {showFilterFieldsList && (
        <Button
          variant={"outline"}
          onClick={handleBack}
          className="self-start dark:bg-gray-900 dark:text-white dark:hover:bg-gray-700"
        >
          {"< Zurück"}
        </Button>
      )}

      {currentFilter && (
        <div className="mb-4 flex flex-col gap-4 rounded border p-4">
          {!currentFilter.field && (
            <FormField
              control={control}
              name={`${filterKey}.field`}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FieldList
                      onClick={(fieldName) => {
                        field.onChange(fieldName);
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          )}

          {currentFilter.field && (
            <>
              <FormField
                control={control}
                name={`${filterKey}.value`}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <fieldset className="space-y-4">
                        <span className="font-bold text-gray-700 dark:text-gray-400">
                          {findFieldByName(currentFilter.field || "")?.label}
                        </span>
                        <input
                          type="text"
                          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:text-sm"
                          value={field.value as string}
                          onChange={field.onChange}
                        />
                      </fieldset>
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={control}
                name={`${filterKey}.condition`}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <div className="my-4 flex flex-col gap-2">
                        {RADIO_OPTIONS.map((option) => (
                          <label
                            key={option.value}
                            className="flex items-center space-x-2"
                          >
                            <input
                              name={field.name}
                              tabIndex={field.value === option.value ? 0 : -1}
                              type="radio"
                              value={option.value}
                              checked={field.value === option.value}
                              onChange={(e) => field.onChange(e.target.value)}
                              className="form-radio checked:bg-green-600 focus:ring-2 focus:ring-green-600 dark:border-gray-600 dark:bg-gray-300 dark:checked:bg-green-600 dark:focus:ring-green-600"
                            />
                            <span className="text-gray-700 dark:text-gray-400">
                              {option.label}
                            </span>
                          </label>
                        ))}
                      </div>
                    </FormControl>
                  </FormItem>
                )}
              />

              <div className="mt-4 flex gap-2">
                <Button
                  disabled={!currentFilter.condition || !currentFilter.value}
                  onClick={() => {
                    setShowFilterFieldsList(false);
                  }}
                  className="btn-alt"
                >
                  Filter anwenden
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
