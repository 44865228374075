import React, { useState, useEffect } from "react";
import { Modal } from "flowbite-react";
import { useAuth } from "../../provider/authProvider";
import { Link } from "react-router-dom";
import axios from "axios";
import SuccessModal from "./SuccessModal";
import validator from "validator";
import analytics from "../../analytics";

// Cookie names constants
const COOKIE_NAMES = {
  SOURCE: "sn",
  PARTNER: "pn",
};

// Utility function to get cookie value
const getCookie = (name) => {
  const cookieName = `${name}=`;
  const cookies = document.cookie.split(";");

  for (let cookie of cookies) {
    cookie = cookie.trim();
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
};

const RegisterModal = ({ isOpen, onClose, initialWebsite }) => {
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState(initialWebsite);
  const [websiteError, setWebsiteError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [touched, setTouched] = useState({ website: false, email: false });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isWebsiteValid, setIsWebsiteValid] = useState(false);
  const [generalError, setGeneralError] = useState("");

  // Get tracking data from cookies
  const getTrackingData = () => {
    const source = getCookie(COOKIE_NAMES.SOURCE);
    const partner = getCookie(COOKIE_NAMES.PARTNER);
    return {
      source: source || "direct",
      partner: partner || "none",
    };
  };
  const handleClose = () => {
    history.pushState(
      "",
      document.title,
      window.location.pathname + window.location.search,
    );
    onClose();
  };
  useEffect(() => {
    setWebsite(initialWebsite);
  }, [initialWebsite]);

  const { isAuthenticated } = useAuth();

  const validateWebsite = (value) => {
    if (!value.trim()) {
      return "Website ist erforderlich";
    }

    // Remove protocol and www if present
    let websiteToCheck = value.trim().toLowerCase();
    websiteToCheck = websiteToCheck.replace(/^(https?:\/\/)?(www\.)?/, "");

    // Regex for website validation
    const websiteRegex =
      /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(\.[a-z]{2,63})?$/;

    if (!websiteRegex.test(websiteToCheck)) {
      return "Ungültige Website";
    }
    return "";
  };

  const validateEmail = (value) => {
    if (!value.trim()) {
      return "E-Mail ist erforderlich";
    }
    if (!validator.isEmail(value)) {
      return "Bitte geben Sie eine gültige E-Mail-Adresse ein!";
    }
    return "";
  };

  const handleWebsiteChange = (e) => {
    const value = e.target.value;
    setWebsite(value);
    setIsWebsiteValid(validateWebsite(value) === "");
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsEmailValid(validateEmail(value) === "");
  };

  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true });
    if (field === "website") {
      setWebsiteError(validateWebsite(website));
    } else if (field === "email") {
      setEmailError(validateEmail(email));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError("");
    setWebsiteError("");
    setEmailError("");

    const websiteValidationResult = validateWebsite(website);
    const emailValidationResult = validateEmail(email);

    setWebsiteError(websiteValidationResult);
    setEmailError(emailValidationResult);
    setTouched({ website: true, email: true });

    if (!websiteValidationResult && !emailValidationResult) {
      setIsSubmitting(true);

      try {
        //console.log("Environment Variables:", import.meta.env);

        // Get tracking data from cookies
        const { source, partner } = getTrackingData();

        const response = await axios.post(
          `${import.meta.env.VITE_APP_API}/api/auth/user_signup`,
          {
            website,
            email,
            source,
            partner,
          },
        );

        if (response.data.code === 200) {
          const accountId = response.data.data;
          analytics.identify(accountId);
          analytics.track("sign_up", {
            method: "email",
            account_id: accountId,
            source_sn: source,
            partner_sn: partner,
          });
          setShowSuccessModal(true);
          onClose();
        } else if (response.data.code === 409) {
          setEmailError("Ein Konto mit dieser E-Mail existiert bereits.");
          setTouched({ ...touched, email: true });
          analytics.track("error", {
            error: "signup_error",
            error_type: "email_duplicate",
            error_message: "Account with this email already exists",
            source_sn: source,
            partner_sn: partner,
          });
        } else {
          setGeneralError(
            "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.",
          );
          analytics.track("error", {
            error: "signup_error",
            error_type: "unexpected_error",
            error_message: "Unexpected error occurred during registration",
            source_sn: source,
            partner_sn: partner,
          });
        }
      } catch (error) {
        console.error("Network or server error:", error);
        setGeneralError(
          "Ein Verbindungsfehler ist aufgetreten. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.",
        );

        // Track network or server error
        analytics.track("error", {
          error: "signup_error",
          error_type: "network_server_error",
          error_message: error.message || "Network or server error occurred",
          source_sn: source,
          partner_sn: partner,
        });
      } finally {
        setIsSubmitting(false);
      }
    } else {
      // Track validation errors
      analytics.track("error", {
        error: "signup_error",
        error_type: "validation_error",
        error_message: "Form validation failed",
        website_error: websiteValidationResult,
        email_error: emailValidationResult,
        source_sn: source,
        partner_sn: partner,
      });
    }
  };

  return (
    <>
      <Modal show={isOpen} onClose={handleClose} size="lg" placement="center">
        <Modal.Header>
          <div className="h3-style text-center">Kostenlos testen!</div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-2">
              <label
                htmlFor="website"
                className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Website
              </label>
              <input
                type="text"
                name="website"
                id="website"
                value={website}
                onChange={handleWebsiteChange}
                onBlur={() => handleBlur("website")}
                className={`form-input transition-all duration-200 ${
                  websiteError && touched.website
                    ? "form-input-error"
                    : isWebsiteValid && touched.website
                      ? "form-input-success"
                      : ""
                }`}
                placeholder="beispiel.de"
              />
              <div className="min-h-[20px]">
                {websiteError && touched.website && (
                  <p className="form-message-error transition-all duration-200">
                    <span className="form-message-highlight">Fehler:</span>{" "}
                    {websiteError}
                  </p>
                )}
                {isWebsiteValid && touched.website && !websiteError && (
                  <p className="form-message-success transition-all duration-200">
                    Die Website-URL wurde erfolgreich validiert.
                  </p>
                )}
              </div>
            </div>
            <div className="mb-2">
              <label htmlFor="email" className="form-label">
                E-Mail
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                onBlur={() => handleBlur("email")}
                className={`form-input transition-all duration-200 ${
                  emailError && touched.email
                    ? "form-input-error"
                    : isEmailValid && touched.email
                      ? "form-input-success"
                      : ""
                }`}
                placeholder="name@beispiel.de"
              />
              <div className="min-h-[20px]">
                {emailError && touched.email && (
                  <p className="form-message-error transition-all duration-200">
                    <span className="form-message-highlight">Fehler:</span>{" "}
                    {emailError}
                  </p>
                )}
                {isEmailValid && touched.email && !emailError && (
                  <p className="form-message-success transition-all duration-200">
                    Die E-Mail-Adresse ist gültig.
                  </p>
                )}
              </div>
            </div>
            {generalError && (
              <p className="form-message-error transition-all duration-200">
                <span className="form-message-highlight">Fehler:</span>{" "}
                {generalError}
              </p>
            )}
            <p className="text-sm text-gray-600 dark:text-white">
              Es gelten unsere{" "}
              <Link
                to="/agb"
                className="text-gray-600 underline decoration-gray-500 dark:text-white"
                target="_blank"
                rel="noopener noreferrer"
              >
                AGB
              </Link>
              .
            </p>
            <button
              type="submit"
              className="btn my-4 w-full py-2.5 text-center"
              disabled={isSubmitting}
            >
              {isSubmitting
                ? "Wird gesendet..."
                : "Jetzt 14 Tage unverbindlich testen"}
            </button>
            <p className="text-center text-sm font-light text-gray-500 dark:text-gray-400">
              Du hast bereits ein Konto?{" "}
              <Link
                to={!isAuthenticated ? "/login" : "/dashboard"}
                className="hover:text-primary-700 font-medium text-primary-600 underline hover:no-underline dark:text-primary-500"
              >
                {!isAuthenticated ? "Login" : "zum Dashboard"}
              </Link>
            </p>
          </form>
        </Modal.Body>
      </Modal>
      <SuccessModal
        show={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
      />
    </>
  );
};

export default RegisterModal;
