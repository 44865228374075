import { MouseEvent } from "react";

interface AddFilterButtonProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const AddFilterButton = ({ onClick }: AddFilterButtonProps) => {
  return (
    <div className="flex flex-col items-center gap-2 dark:text-gray-200">
      Jetzt ersten Filter für <br />
      das Segment anlegen.
      <span className="btn" onClick={onClick}>
        Filter hinzufügen
      </span>
    </div>
  );
};
