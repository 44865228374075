import { LOCAL_STORAGE_KEYS } from "@/constants";
import { SORT_LIST_SEARCH_OPTIONS } from "@/constants/sortListSearchOptions";
import { toggleDropdown } from "@/helpers";
import { useClickOutside } from "@/hooks";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";

const baseUrl = import.meta.env.VITE_APP_API || "";

type useSortPanelProps = {
  queryId: string | undefined;
  setIsSortOpen: Dispatch<SetStateAction<boolean>>;
};

export type SortingType = {
  label: string;
  value: string;
  image: string;
  tooltip: string;
};

export const useSortPanel = ({ queryId, setIsSortOpen }: useSortPanelProps) => {
  const params = useParams();
  const exportButtonRef = useRef<HTMLButtonElement>(null);

  const {
    containerRef,
    isOpen: isExportOpen,
    setIsOpen: setIsExportOpen,
  } = useClickOutside(exportButtonRef);

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedExportValue, setSelectedExportValue] =
    useState<SortingType | null>(null);

  const sortValue = searchParams.get("sort") || "";

  const getLabelByValue = useCallback((value?: string) => {
    const item = SORT_LIST_SEARCH_OPTIONS.find(
      (entry) => entry.value === value,
    );
    return item ? (
      <span className="flex items-center">
        {item.label}
        {item.icon || null}
      </span>
    ) : (
      "Sortierung"
    );
  }, []);

  const handleSortClick = useCallback(
    (value: string) => {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.set("sort", value);
        return newParams;
      });
      toggleDropdown(setIsSortOpen);
    },
    [setSearchParams],
  );

  const handleExportClick = useCallback((item: SortingType) => {
    setSelectedExportValue(item);
    toggleDropdown(setIsExportOpen);
  }, []);

  const fetchFile = async () => {
    const currentToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);

    const headers = { Authorization: `Bearer ${currentToken}` };

    const queryParams = new URLSearchParams({
      export_type: selectedExportValue?.value ?? "",
      query_id: queryId ?? "",
      sort: sortValue || "date_desc",
      domain: params.domain!,
    });

    try {
      const response = await fetch(
        `${baseUrl}/api/exports/spreadsheet?${queryParams}`,
        {
          method: "GET",
          headers,
        },
      );

      const responseHeaders = response.headers;

      if (responseHeaders) {
        const fileNameHeader = responseHeaders.get("content-disposition");

        const filenameWithExtras = fileNameHeader?.split("filename=")[1];

        const fileName = filenameWithExtras?.replace(/["\s]/g, "");

        const data = await response.blob();

        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName ?? "";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      console.log("Error fetching file:", err);
    }
  };

  useEffect(() => {
    if (!selectedExportValue) return;

    fetchFile();
    setSelectedExportValue(null);
  }, [selectedExportValue]);

  return {
    containerRef,
    exportButtonRef,
    isExportOpen,
    sortValue,
    selectedExportValue,
    setIsExportOpen,
    getLabelByValue,
    handleSortClick,
    handleExportClick,
    toggleDropdown,
  };
};
