import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Drawer,
  Typography,
  IconButton,
  Textarea,
} from "@material-tailwind/react";
import { Clipboard } from "flowbite-react";

function ApiDetailsDrawer({ open, onClose }) {
  const [apiKey, setApiKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showApiKey, setShowApiKey] = useState(false);
  const [confirmState, setConfirmState] = useState(false);

  useEffect(() => {
    if (open) {
      fetchApiKey();
      setShowApiKey(false);
      setConfirmState(false);
    }
  }, [open]);

  const fetchApiKey = async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API}/api/account/api_key_get`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setShowApiKey(false);
      setApiKey(response.data.api_key);
    } catch (err) {
      console.error("Fehler beim Abrufen des API-Schlüssels:", err);
      setError(
        "Fehler beim Abrufen des API-Schlüssels. Bitte versuchen Sie es erneut.",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const createNewApiKey = async () => {
    if (!confirmState) {
      setConfirmState(true);
      setTimeout(() => setConfirmState(false), 3000); // Reset after 3 seconds
      return;
    }

    setIsLoading(true);
    setError("");
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_API}/api/account/api_key_create`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setApiKey(response.data.api_key);
    } catch (err) {
      console.error("Fehler beim Erstellen eines neuen API-Schlüssels:", err);
      setError(
        "Fehler beim Erstellen eines neuen API-Schlüssels. Bitte versuchen Sie es erneut.",
      );
    } finally {
      setIsLoading(false);
      setConfirmState(false);
    }
  };

  const toggleApiKeyVisibility = () => {
    setShowApiKey(!showApiKey);
  };

  const displayApiKey = showApiKey ? apiKey : "•".repeat(apiKey.length);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      placement="right"
      className="h-full overflow-y-auto border-l drop-shadow-2xl dark:border-l-gray-600 dark:bg-gray-800 sm:w-full md:w-2/3 lg:w-1/2 2xl:w-1/3"
      size={10000}
      overlay={true}
      overlayProps={{
        className: "fixed inset-0 bg-black bg-opacity-60",
      }}
    >
      <div className="-mt-[1px] flex items-center justify-between border-b px-4 py-3 dark:border-gray-700">
        <Typography
          variant="h4"
          color="blue-gray"
          className="dark:text-green-400"
        >
          API
        </Typography>
        <IconButton
          variant="text"
          color="blue-gray"
          onClick={onClose}
          className="dark:text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>
      <div className="p-4">
        <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
          API-Schlüssel-Verwaltung
        </h2>
        <p className="mb-6 text-gray-500 dark:text-gray-400">
          Nutzen Sie Ihren API-Schlüssel, um eine sichere Verbindung zu unserer
          API herzustellen.
          <br />
          <br />
          Alle relevanten Informationen, inklusive API-Endpunkte und
          <br />
          Dokumentation, finden Sie hier:
          <br />
          <a
            className="font-semibold text-gray-900 underline decoration-blue-500 dark:text-white"
            target="_blank"
            rel="noopener noreferrer"
            href="https://hub.webmetic.de/docs"
          >
            API-Dokumentation
          </a>
        </p>

        {error && (
          <div className="mb-4 rounded-lg bg-red-100 p-4 text-red-700 dark:bg-red-200 dark:text-red-800">
            {error}
          </div>
        )}

        <label
          htmlFor="api-key"
          className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
        >
          API-Schlüssel:
        </label>
        <div className="relative mb-4">
          <input
            id="api-key"
            type="text"
            className="col-span-6 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-500 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            value={displayApiKey}
            disabled
            readOnly
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-14">
            <button
              type="button"
              className="absolute end-2 top-1/2 inline-flex -translate-y-1/2 items-center justify-center rounded-lg p-2 text-gray-500 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700"
              onClick={toggleApiKeyVisibility}
            >
              {showApiKey ? (
                <svg
                  className="h-6 w-6 text-gray-600 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              ) : (
                <svg
                  className="h-6 w-6 text-gray-600 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeWidth="2"
                    d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
                  />
                  <path
                    stroke="currentColor"
                    strokeWidth="2"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              )}
            </button>
          </div>
          <div className="absolute end-28 top-1/2 mr-3">
            <Clipboard.WithIconText className="" valueToCopy={apiKey} />
          </div>
        </div>
        <button
          type="button"
          className={`btn w-full ${
            confirmState ? "bg-red-500 hover:bg-red-600" : ""
          }`}
          onClick={createNewApiKey}
          disabled={isLoading}
        >
          {isLoading
            ? "Wird geladen..."
            : confirmState
              ? "Achtung: Alter Schlüssel wird ungültig! Zum Bestätigen erneut klicken"
              : "Neuen API-Schlüssel generieren"}
        </button>
      </div>
    </Drawer>
  );
}

export default ApiDetailsDrawer;
