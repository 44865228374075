import { useEffect, useRef } from "react";
import CompaniesContent from "./company-content";
import LeadRating from "./lead-ratings";
import CompanySessions from "./company-sessions";
import { useCompanyDetails } from "@/hooks";
import { Loader } from "@/components";
import { useSearchParams } from "react-router-dom";

interface CompanyDetailsProps {
  company_id: string;
  from_date?: string;
  to_date?: string;
}

export const CompanyDetailsComponent = ({
  company_id,
  from_date,
  to_date,
}: CompanyDetailsProps) => {
  const [searchParams] = useSearchParams();

  const count = searchParams.get("count");

  const {
    data: companiesDetails,
    isLoading,
    error,
  } = useCompanyDetails(
    {
      company_id,
      from_date,
      to_date,
    },
    !!company_id && !!+(count || ""),
  );

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [company_id, from_date, to_date]);

  return (
    <div ref={containerRef} className="h-full w-full dark:bg-gray-900">
      {isLoading && (
        <div className="p-10">
          <Loader />
        </div>
      )}
      {error && (
        <div className="flex h-full w-full justify-center p-10">
          <p className="text-red-500">
            Es wurden keine Unternehmen in dem Segment gefunden.
          </p>
        </div>
      )}
      {companiesDetails && (
        <div className="flex h-full w-full flex-col gap-4 border-l bg-white px-4 py-2 dark:border-gray-600 dark:bg-gray-900">
          <CompaniesContent companiesDetails={companiesDetails} />
          <LeadRating companiesDetails={companiesDetails} />
          <CompanySessions companiesDetails={companiesDetails} />
        </div>
      )}
    </div>
  );
};
