import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { format } from "date-fns";

import { type ReactNode, useEffect } from "react";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";

import { useCompanyFilterContext } from "@/hooks/useCompanyFilterContext";
import { useCompaniesFilterView } from "@/hooks";
import { useParams } from "react-router-dom";
import { getDateRange } from "@/utils";
import { IconButton } from "@/components";
import { XIcon } from "@/assets/icons";
import { getFiltersListString } from "@/routes/companies/companies-list/utils";
import { PeriodSelector } from "../period-selector";
import { SegmentFiltersList } from "../segment-filters-list";
import { AddFilterButton } from "../add-filter-button";
import { NewFilterForm } from "../new-filter-form";
import { CompaniesList } from "@/routes/companies/components";
import { CompanyDetailsComponent } from "@/routes/companies/company-details/company-details-component";
import { SaveSegmentModal } from "../save-segment-modal";

interface Props {
  trigger: ReactNode;
}

function SegmentsDrawer({ trigger }: Props) {
  const params = useParams();

  const { periodList } = useCompaniesFilterView();

  const {
    filtersForm,
    showFilterFieldsList,
    isOpenSaveModal,
    selectedCompany,
    isOpenFilterDrawer,
    isEditMode,
    segmentToEdit,
    companyList,
    pagination,
    setIsOpenFilterDrawer,
    setSelectedCompany,
    appendGroup,
    setIsOpenSaveModal,
    setShowFilterFieldsList,
    handleClose,
    handleSubmit,
    setDomain,
    fetchNextPage,
  } = useCompanyFilterContext();

  const period = filtersForm?.watch("period");
  const filters = filtersForm?.watch("query");
  const periodRange = period && getDateRange(period);

  const filterList = getFiltersListString(filters);

  useEffect(() => {
    setDomain(params.domain || "");
  }, [params.domain]);

  return (
    <>
      {filtersForm && (
        <Form {...filtersForm}>
          <form
            id="segmentsForm"
            onSubmit={filtersForm.handleSubmit(handleSubmit)}
          >
            <Drawer
              direction="left"
              open={isOpenFilterDrawer}
              onOpenChange={(open) => {
                if (!open) {
                  handleClose();
                  return;
                }

                setIsOpenFilterDrawer(open);
              }}
            >
              <DrawerTrigger
                asChild
                onClick={() => setIsOpenFilterDrawer(true)}
              >
                {trigger}
              </DrawerTrigger>
              <DrawerContent
                className={`fixed inset-y-0 z-50 flex flex-row border-r bg-background dark:border-r-gray-600 dark:bg-gray-900 ${selectedCompany && "w-full"}`}
              >
                <div className="flex h-full w-72 min-w-72 flex-col">
                  <DrawerHeader className="border-b dark:border-b-gray-600">
                    <DrawerTitle>
                      {isEditMode ? segmentToEdit?.label : "Neues Segment"}
                    </DrawerTitle>
                    <DrawerDescription>
                      {isEditMode
                        ? "Bearbeiten Sie Ihr Segment."
                        : "Erstellen Sie ein neues Segment."}
                    </DrawerDescription>
                  </DrawerHeader>
                  <div className="grow space-y-8 overflow-y-scroll p-4">
                    <div className="space-y-8">
                      {!showFilterFieldsList && (
                        <FormField
                          control={filtersForm.control}
                          name={"period"}
                          render={({ field }) => (
                            <FormItem>
                              <FormControl>
                                <PeriodSelector
                                  periodList={periodList}
                                  value={field.value}
                                  onChange={(newValue) =>
                                    field.onChange(newValue)
                                  }
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      )}
                      {!showFilterFieldsList &&
                        filters &&
                        !!filters[0]?.length && <SegmentFiltersList />}
                      {!showFilterFieldsList &&
                        (!filters || !filters[0]?.length) && (
                          <AddFilterButton
                            onClick={() => {
                              appendGroup([
                                [{ field: "", condition: "", value: "" }],
                              ]);
                              setShowFilterFieldsList(true);
                            }}
                          />
                        )}
                      {showFilterFieldsList && <NewFilterForm />}
                    </div>
                  </div>
                  <DrawerFooter className="border-r border-t dark:border-r-gray-600 dark:border-t-gray-600">
                    <Button
                      className="btn-alt"
                      onClick={() => {
                        setIsOpenSaveModal(true);
                        setIsOpenFilterDrawer(false);
                      }}
                      disabled={
                        !(filters || [])[0]?.length ||
                        !(filters || [])[0][0]?.value
                      }
                    >
                      {isEditMode ? "Aktualisieren" : "Speichern"}
                    </Button>
                    <Button
                      // variant="outline"
                      onClick={handleClose}
                      className="btn-alt"
                    >
                      Abbrechen
                    </Button>
                  </DrawerFooter>
                </div>
                <CompaniesList
                  flatList={companyList}
                  total={pagination.total}
                  hasNextPage={!pagination.isLast}
                  fetchNextPage={() => fetchNextPage(pagination)}
                  filtersList={filterList}
                />
                {selectedCompany && (
                  <div className="relative w-full overflow-y-scroll">
                    <CompanyDetailsComponent
                      company_id={selectedCompany.company_id}
                      from_date={
                        periodRange?.from &&
                        format(periodRange.from, "yyyy-MM-dd")
                      }
                      to_date={
                        periodRange?.to && format(periodRange.to, "yyyy-MM-dd")
                      }
                    />
                    <IconButton
                      icon={<XIcon color="#FF4C4C" />}
                      color="semiTransparent"
                      className="pointer absolute right-4 top-1"
                      onClick={() => {
                        setSelectedCompany(null);
                      }}
                    />
                  </div>
                )}
              </DrawerContent>
            </Drawer>

            {isOpenSaveModal && (
              <SaveSegmentModal
                isOpen={isOpenSaveModal}
                onClose={() => {
                  setIsOpenSaveModal(false);
                  setIsOpenFilterDrawer(true);
                }}
                title={
                  isEditMode
                    ? "Änderungen speichern"
                    : "Neues Segment erstellen"
                }
              />
            )}
          </form>
        </Form>
      )}
    </>
  );
}

export default SegmentsDrawer;
