import { MoveDownIcon, MoveUpIcon } from "lucide-react";

export const SORT_LIST_SEARCH_OPTIONS = [
  {
    label: "Name",
    value: "name_asc",
    icon: <MoveDownIcon className="h-4" />,
  },
  {
    label: "Name",
    value: "name_desc",
    icon: <MoveUpIcon className="h-4" />,
  },
  {
    label: "Datum",
    value: "date_asc",
    icon: <MoveDownIcon className="h-4" />,
  },
  {
    label: "Datum",
    value: "date_desc",
    icon: <MoveUpIcon className="h-4" />,
  },
  {
    label: "Score",
    value: "score_asc",
    icon: <MoveDownIcon className="h-4" />,
  },
  {
    label: "Score",
    value: "score_desc",
    icon: <MoveUpIcon className="h-4" />,
  },
];
