import { FilterCard } from "./filter-card";
import { findFieldByName } from "../utils";
import { Button } from "@/components/ui/button";
import { useCompanyFilterContext } from "@/hooks/useCompanyFilterContext";

export const SegmentFiltersList = () => {
  const { filtersForm, appendGroup, setShowFilterFieldsList, setIds } =
    useCompanyFilterContext();

  const filters = filtersForm?.watch("query");

  const handleAddNewGroup = () => {
    appendGroup([[{ field: "", condition: "", value: "" }]]);
    setShowFilterFieldsList(true);
    setIds({
      groupId: `${filters?.length}`,
      filterId: "0",
    });
  };

  return (
    <>
      {filters?.map((filterGroup, groupIndex) => {
        return (
          <div className="flex flex-col gap-4" key={groupIndex}>
            <div className="flex flex-col gap-4 rounded-md border border-gray-500 p-2 dark:border-gray-600">
              {Object.values(filterGroup)
                .filter((val) => typeof val !== "string")
                .map((filter, filterIndex) => {
                  const section = findFieldByName(filter.field);
                  const isLastItem = filterIndex === filterGroup.length - 1;

                  return (
                    <FilterCard
                      key={`${groupIndex}-${filterIndex}`}
                      filter={filter}
                      filterIndex={filterIndex}
                      groupIndex={groupIndex}
                      section={section}
                      isLastItem={isLastItem}
                    />
                  );
                })}
            </div>

            <Button
              variant={"outline"}
              className="w-fit dark:bg-gray-900 dark:text-white dark:hover:bg-gray-700"
              onClick={handleAddNewGroup}
            >
              ODER
            </Button>
          </div>
        );
      })}
    </>
  );
};
