import { GERMAN_LABELS } from "@/constants";
import { CalendarIcon } from "lucide-react";
import { CompaniesFilterDate } from "../companies-filter.loader";
import { ChangeEvent, useEffect } from "react";
import { useCompanyFilterContext } from "@/hooks/useCompanyFilterContext";

interface PeriodSelectorProps {
  periodList: CompaniesFilterDate[];
  value: CompaniesFilterDate;
  onChange: (period: CompaniesFilterDate) => void;
}

export const PeriodSelector = ({
  periodList,
  value,
  onChange,
}: PeriodSelectorProps) => {
  const { filtersForm } = useCompanyFilterContext();

  const handlePeriodChange = (selectedPeriod: CompaniesFilterDate) => {
    onChange(selectedPeriod);
  };

  const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedLabel = event.target.value;
    const selectedPeriod = periodList.find(
      (item) => item.label === selectedLabel,
    );

    if (selectedPeriod) {
      handlePeriodChange(selectedPeriod);
    }
  };

  const period = filtersForm?.watch("period");

  useEffect(() => {
    if (period && !Object.values(period).length) {
      filtersForm?.setValue("period", periodList[0]);
    }
  }, [period]);

  return (
    <div className="flex items-center gap-1 dark:text-gray-400">
      <CalendarIcon />
      Zeitraum:
      <div className="flex-1 border-b bg-background p-2 dark:border-gray-600 dark:bg-gray-900">
        <select
          id="countries"
          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
          value={value.label}
          onChange={handleSelect}
        >
          {periodList.map((item) => {
            return (
              <option
                key={item.label}
                className="truncate capitalize"
                value={item.label}
              >
                {GERMAN_LABELS[item.label] || item.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};
