import React, { useState, useEffect } from "react";
import axios from "axios";
import { Drawer, Typography, IconButton } from "@material-tailwind/react";

function ProfileDrawer({ open, onClose }) {
  const [profileData, setProfileData] = useState({
    email: "",
    first_name: "",
    last_name: "",
  });
  const [passwordData, setPasswordData] = useState({
    current_password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordLoading, setIsPasswordLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState({ type: "", text: "" });
  const [passwordError, setPasswordError] = useState("");
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    specialChar: false,
    upperCase: false,
    lowerCase: false,
    match: false,
  });

  useEffect(() => {
    if (open) {
      fetchProfileData();
    }
  }, [open]);

  const fetchProfileData = async () => {
    setIsLoading(true);
    setMessage({ type: "", text: "" });

    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API}/api/account/profile_data`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );

      setProfileData(response.data);
    } catch (err) {
      console.error("Error fetching profile data:", err);
      setMessage({
        type: "error",
        text: "Failed to fetch profile data. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateProfileData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage({ type: "", text: "" });
    try {
      const response = await axios.patch(
        `${import.meta.env.VITE_APP_API}/api/account/profile_data_update`,
        profileData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      if (response.data.message === "Profile updated successfully") {
        setMessage({
          type: "success",
          text: "Profil wurde erfolgreich aktualisiert.",
        });
        // If email was updated, you might want to update the stored token or re-authenticate the user
        if (response.data.email !== profileData.email) {
          // Handle email update (e.g., logout user and ask to re-login)
        }
      }
    } catch (err) {
      console.error("Error updating profile data:", err);
      setMessage({
        type: "error",
        text: "Fehler beim Aktualisieren des Profils. Bitte versuchen Sie es erneut.",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const validatePasswordRequirements = (password, confirmPassword) => {
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const upperCaseRegex = /[A-Z]/;
    const lowerCaseRegex = /[a-z]/;

    setPasswordRequirements({
      length: password.length >= 8,
      specialChar: specialCharRegex.test(password),
      upperCase: upperCaseRegex.test(password),
      lowerCase: lowerCaseRegex.test(password),
      match: password === confirmPassword && password !== "",
    });
  };

  const validatePasswords = () => {
    if (!Object.values(passwordRequirements).every(Boolean)) {
      setPasswordError("Das Passwort erfüllt nicht alle Anforderungen.");
      return false;
    }

    setPasswordError("");
    return true;
  };

  const handlePasswordInputChange = (e) => {
    const { name, value } = e.target;
    const newPasswordData = { ...passwordData, [name]: value };
    setPasswordData(newPasswordData);
    validatePasswordRequirements(
      newPasswordData.new_password,
      newPasswordData.confirm_new_password,
    );
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    if (!validatePasswords()) {
      return;
    }
    setIsPasswordLoading(true);
    setMessage({ type: "", text: "" });
    try {
      const response = await axios.patch(
        `${import.meta.env.VITE_APP_API}/api/account/profile_password_update`,
        {
          current_password: passwordData.current_password,
          new_password: passwordData.new_password,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      if (response.data.message === "Password updated successfully") {
        setMessage({
          type: "success",
          text: "Passwort wurde erfolgreich aktualisiert.",
        });
        setPasswordData({
          current_password: "",
          new_password: "",
          confirm_new_password: "",
        });
        setPasswordRequirements({
          length: false,
          specialChar: false,
          upperCase: false,
          lowerCase: false,
          match: false,
        });
      }
    } catch (err) {
      console.error("Error updating password:", err);
      setMessage({
        type: "error",
        text: "Fehler beim Aktualisieren des Passworts. Bitte überprüfen Sie Ihr aktuelles Passwort und versuchen Sie es erneut.",
      });
    } finally {
      setIsPasswordLoading(false);
    }
  };

  const CheckIcon = ({ isValid }) => (
    <svg
      className={`me-2 h-3.5 w-3.5 ${isValid ? "text-green-500 dark:text-green-400" : "text-gray-300 dark:text-gray-600"} flex-shrink-0`}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
    </svg>
  );
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };
  return (
    <Drawer
      open={open}
      onClose={onClose}
      placement="right"
      className="h-full overflow-y-auto border-l drop-shadow-lg dark:border-l-gray-600 dark:bg-gray-800 sm:w-full md:w-2/3 lg:w-1/2 2xl:w-1/3"
      size={10000}
      overlay={true}
      overlayProps={{
        className: "fixed inset-0 bg-black bg-opacity-60",
      }}
    >
      <div className="-mt-[1px] flex items-center justify-between border-b px-4 py-3 dark:border-b-gray-600">
        <Typography
          variant="h4"
          color="blue-gray"
          className="dark:text-green-400"
        >
          Benutzerprofil
        </Typography>
        <IconButton variant="text" color="blue-gray" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>
      <div className="p-4">
        <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
          Benutzerprofil anpassen
        </h2>
        <p className="mb-6 text-gray-500 dark:text-gray-400">
          Verwalten Sie hier Ihre persönlichen Informationen wie E-Mail-Adresse
          und Name.
        </p>

        <form onSubmit={updateProfileData}>
          <div className="grid grid-cols-6 gap-2">
            <div className="col-span-6">
              <label
                htmlFor="email"
                className="mb-1 block text-sm font-medium text-gray-900 dark:text-white"
              >
                E-Mail
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={profileData.email}
                disabled
                className="block w-full rounded-lg border border-gray-300 bg-gray-100 p-2.5 text-gray-600 shadow-sm dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 sm:text-sm"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="first_name"
                className="mb-1 block text-sm font-medium text-gray-900 dark:text-white"
              >
                Vorname
              </label>
              <input
                type="text"
                name="first_name"
                id="first_name"
                value={profileData.first_name}
                onChange={handleInputChange}
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:text-sm"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="last_name"
                className="mb-1 block text-sm font-medium text-gray-900 dark:text-white"
              >
                Nachname
              </label>
              <input
                type="text"
                name="last_name"
                id="last_name"
                value={profileData.last_name}
                onChange={handleInputChange}
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:text-sm"
              />
            </div>
            <div className="col-span-6">
              <button
                className="btn mt-2 w-full"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Wird gespeichert..." : "Profil speichern"}
              </button>
            </div>
          </div>
        </form>

        <h2 className="mb-2 mt-6 text-lg font-semibold text-gray-900 dark:text-white">
          Passwort ändern
        </h2>
        <p className="mb-6 text-gray-500 dark:text-gray-400">
          Hier können Sie Ihr Passwort ändern. Bitte geben Sie Ihr aktuelles
          Passwort zur Bestätigung ein.
        </p>

        <form onSubmit={updatePassword}>
          <div className="grid grid-cols-6 gap-2">
            <div className="col-span-6">
              <label
                htmlFor="current_password"
                className="mb-1 block text-sm font-medium text-gray-900 dark:text-white"
              >
                Aktuelles Passwort
              </label>
              <input
                type="password"
                name="current_password"
                id="current_password"
                value={passwordData.current_password}
                onChange={handlePasswordInputChange}
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:text-sm"
              />
            </div>
            <div className="col-span-6">
              <label
                htmlFor="new_password"
                className="mb-1 block text-sm font-medium text-gray-900 dark:text-white"
              >
                Neues Passwort
              </label>
              <input
                type="password"
                name="new_password"
                id="new_password"
                value={passwordData.new_password}
                onChange={handlePasswordInputChange}
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:text-sm"
              />
            </div>
            <div className="col-span-6">
              <label
                htmlFor="confirm_new_password"
                className="mb-1 block text-sm font-medium text-gray-900 dark:text-white"
              >
                Neues Passwort bestätigen
              </label>
              <input
                type="password"
                name="confirm_new_password"
                id="confirm_new_password"
                value={passwordData.confirm_new_password}
                onChange={handlePasswordInputChange}
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:text-sm"
              />
            </div>
            <div className="col-span-6 mt-4">
              <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
                Passwortanforderungen:
              </h2>
              <ul className="max-w-md list-inside space-y-1 text-gray-500 dark:text-gray-400">
                <li className="flex items-center">
                  <CheckIcon isValid={passwordRequirements.length} />
                  Mindestens 8 Zeichen
                </li>
                <li className="flex items-center">
                  <CheckIcon isValid={passwordRequirements.specialChar} />
                  Mindestens ein Sonderzeichen, z.B. ! @ # ?
                </li>
                <li className="flex items-center">
                  <CheckIcon isValid={passwordRequirements.upperCase} />
                  Mindestens ein Großbuchstabe
                </li>
                <li className="flex items-center">
                  <CheckIcon isValid={passwordRequirements.lowerCase} />
                  Mindestens ein Kleinbuchstabe
                </li>
                <li className="flex items-center">
                  <CheckIcon isValid={passwordRequirements.match} />
                  Passwörter stimmen überein
                </li>
              </ul>
            </div>

            {passwordError && (
              <div className="col-span-6">
                <p className="text-sm text-red-600">{passwordError}</p>
              </div>
            )}
            <div className="col-span-6">
              <button
                className="btn mt-2 w-full"
                type="submit"
                disabled={isPasswordLoading}
              >
                {isPasswordLoading ? "Wird geändert..." : "Passwort ändern"}
              </button>
            </div>
          </div>
        </form>
      </div>
      {message.text && (
        <div
          className={`m-4 rounded-lg p-4 ${
            message.type === "error"
              ? "bg-red-100 text-red-700"
              : "bg-green-100 text-green-700"
          }`}
        >
          {message.text}
        </div>
      )}
      {error && (
        <div className="m-4 rounded-lg bg-red-100 p-4 text-red-700">
          {error}
        </div>
      )}
    </Drawer>
  );
}

export default ProfileDrawer;
