import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { useEffect, useMemo, useState } from "react";
import { DateRange } from "react-day-picker";
import { format, getYear, parseISO } from "date-fns";
import useUser from "@/hooks/useUser.ts";
import { useGetSegmentsListQuery, useSegmentMutations } from "@/hooks/index.ts";
import {
  CompaniesFilterDate,
  companiesFilterQueryOptions,
} from "@/routes/companies/companies-filter/companies-filter.loader";
import { GERMAN_LABELS, SEGMENTS_LIST } from "@/constants";
import { getDateRange, toValidRangeDate } from "@/utils";

export const useCompaniesFilterView = () => {
  const user = useUser();
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: periodListData } = useSuspenseQuery(
    companiesFilterQueryOptions(params.domain!),
  );

  const periodList = periodListData
    .map((item) => {
      if (item.label.includes("this_year")) {
        const year = getYear(new Date()).toString();
        return {
          ...item,
          label: year,
        };
      }
      if (item.label.includes("month")) {
        const monthName = format(new Date(item.date_start), "MMMM");
        const year = getYear(new Date(item.date_start));
        const formattedMonth =
          monthName.charAt(0).toUpperCase() + monthName.slice(1).toLowerCase();
        return {
          ...item,
          label: `${GERMAN_LABELS[formattedMonth.toLowerCase()]} ${year}`,
        };
      }
      return item;
    })
    .sort((a, b) => {
      if (a.label.includes("all_time") && !b.label.includes("all_time")) {
        return 1;
      }
      if (!a.label.includes("all_time") && b.label.includes("all_time")) {
        return -1;
      }
      return 0;
    });

  const queryClient = useQueryClient();

  const { data: segmentsList } = useGetSegmentsListQuery({
    domain: params.domain || "",
  });

  const { deleteSegmentMutation } = useSegmentMutations();

  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: toValidRangeDate(searchParams.get("from_date")) || undefined,
    to: toValidRangeDate(searchParams.get("to_date")) || undefined,
  });

  const handleDeleteSegment = (id: string) => {
    deleteSegmentMutation(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [SEGMENTS_LIST] });
      },
    });
  };

  const resetSelectedCompany = (domain = params.domain) => {
    const n = new URLSearchParams(searchParams);
    n.delete("company_id");
    n.delete("segment_id");
    n.delete("from_date");
    n.delete("to_date");

    navigate({
      pathname: `/dashboard/${domain}/companies`,
      search: n.toString(),
    });
  };

  const handleSelectDateRangeOption = (item: CompaniesFilterDate) => () => {
    const range = getDateRange(item);
    searchParams.set("count", `${item.companies}`);
    updateSearchParamsDateRange(range);
  };

  const updateSearchParamsDateRange = (dr?: DateRange) => {
    resetSelectedCompany();
    setSearchParams((p) => {
      const n = new URLSearchParams(p);
      if (n.has("segment_id")) {
        n.delete("segment_id");
      }

      if (dr?.from) n.set("from_date", format(dr.from, "yyyy-MM-dd"));
      else n.delete("from_date");
      if (dr?.to) n.set("to_date", format(dr.to, "yyyy-MM-dd"));
      else n.delete("to_date");
      return n;
    });
  };

  const handleUpdateSearchParams = () => updateSearchParamsDateRange(dateRange);

  useEffect(() => {
    const from = searchParams.get("from_date");
    const to = searchParams.get("to_date");
    const sort = searchParams.get("sort");

    if (!from || !to) {
      const firstValidPeriod = periodList.find((item) => item.companies > 0);
      searchParams.set("count", `${firstValidPeriod?.companies}`);

      const data = {
        from: toValidRangeDate(firstValidPeriod?.date_start || ""),
        to: toValidRangeDate(firstValidPeriod?.date_end || ""),
      };

      firstValidPeriod && updateSearchParamsDateRange(data);

      if (!sort) {
        searchParams.set("sort", "date_desc");
      }
    }
  }, [searchParams]);

  const selectedItem = useMemo(() => {
    const from = searchParams.get("from_date");
    const to = searchParams.get("to_date");

    if (!from || !to) return null;

    const f = format(parseISO(from), "MM/dd/yyyy");
    const t = format(parseISO(to), "MM/dd/yyyy");

    for (const item of periodList) {
      const startDate = format(parseISO(item.date_start), "MM/dd/yyyy");
      const endDate = format(parseISO(item.date_end), "MM/dd/yyyy");

      if (f === startDate && t === endDate) {
        return item;
      }
    }

    return null;
  }, [searchParams, periodList]);

  return {
    user,
    periodList,
    params,
    segmentsList,
    dateRange,
    selectedItem,
    setDateRange,
    resetSelectedCompany,
    handleDeleteSegment,
    handleSelectDateRangeOption,
    handleUpdateSearchParams,
  };
};
