import { XIcon } from "@/assets/icons";
import { IconButton } from "@/components";
import { Button } from "@/components/ui/button";
import { useCompanyFilterContext } from "@/hooks/useCompanyFilterContext";
import { QueryField } from "@/types";
import { Section } from "@/types/segment";
import { useFilterCard } from "./useFilterCard";

interface FilterCardProps {
  filter: QueryField;
  filterIndex: number;
  section: Section | null;
  isLastItem: boolean;
  groupIndex: number;
}
export const FilterCard = ({
  filter,
  section,
  isLastItem,
  groupIndex,
  filterIndex,
}: FilterCardProps) => {
  const { filtersForm, setShowFilterFieldsList, setIds } =
    useCompanyFilterContext();

  const { handleAddNewFilter, handleRemoveFilter } = useFilterCard({
    filtersForm,
    groupIndex,
    filterIndex,
    setIds,
    setShowFilterFieldsList,
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="group relative flex flex-wrap items-center gap-1 rounded-md border border-gray-500 p-4 text-webmetic_dark-400 dark:border-gray-600 dark:text-gray-400">
        {section?.icon}
        <p className="">{section?.label}</p>
        <p className="dark:text-white">{filter.condition}</p>
        <p className="font-bold dark:text-white">{filter.value}</p>
        <IconButton
          icon={<XIcon color="#FF4C4C" />}
          color="semiTransparent"
          className="pointer absolute right-0 top-0 opacity-0 transition-opacity duration-200 group-hover:opacity-100"
          onClick={handleRemoveFilter}
        />
      </div>

      {isLastItem && (
        <Button
          variant={"secondary"}
          className="w-fit dark:bg-blue-gray-800 dark:text-white dark:hover:bg-blue-gray-600"
          onClick={handleAddNewFilter}
        >
          UND
        </Button>
      )}
    </div>
  );
};
